
/** HAMBURGER MENU*/
.navbar-small__icon span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.1s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.1s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.15s ease;
  }
  .navbar-small__icon span:nth-last-child(1){    
      transform-origin: 0% 100%;
      /* transform: rotate(-45deg) translate(0,1px) */   
  }
  